import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['buttons', 'container', 'dialog', 'title', 'content']

  connect() {
    this.closed = true;
  }

  close() {
    this.closed = true;
    this.containerTarget.classList.remove('open')
    this.buttonsTarget.childNodes[0].classList.remove('open');
    this.buttonsTarget.childNodes[1].classList.remove('open');
    this.buttonsTarget.childNodes[2].classList.remove('open');
  }

  transitionend() {
    if (this.closed) {
      this.dialogTarget.classList.add('closed')
    }
  }

  open(e) {
    e.preventDefault();
    console.log('open');

    const button = e.currentTarget;
    const id = button.attributes.href.textContent;

    this.closed = false;
    this.dialogTarget.classList.remove('closed')

    this.dialogTarget.style.top = 'auto';
    this.dialogTarget.style.left = 'auto';

    setTimeout(() => {

      console.log(this.dialogTarget)
      this.dialogTarget.style.top = button.offsetTop + 'px';
      this.dialogTarget.style.left = button.offsetLeft + 'px';
      console.log(`${id} ${button.offsetTop} / ${button.offsetLeft}`)

      setTimeout(() => {
        console.log('Move Open')
        console.log(this.dialogTarget)
        button.classList.add('open');
        this.containerTarget.classList.add('open');

        this.titleTarget.innerHTML = id
        this.contentTarget.innerHTML = document.querySelector(id).innerHTML

      }, 25)
    }, 25)
  }
}
